/**
 * These errors are not very helpful to the user in the context of looking at the Subscriber/SIM management UI, so they get trapped and replaced with more helpful errors.
 *
 * This error substitution was moved here as part of the fix for https://app.clubhouse.io/soracom/story/12557/incorrect-permissions-error-messages-are-displayed-for-sam-user-when-searching-sims
 *
 * The old code, when this error substitution was done at the lower SIM/Subscriber enumerator level, trapped these 3 errors. So we trap the same ones, even though in my testing I have only seen `SCH0006`.
 */
export const samUserErrorCodes = [
  'COM0017', // SAM permission required
  'SEM0049', // SAM permission required
  'SCH0006', // (?) query not permitted
];

export function isSamUserHasInsufficientPrivilegeError(error: any): boolean {
  const code = error?.data?.code;
  return samUserErrorCodes.includes(code);
}
